var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buy-subscription",attrs:{"id":"BuySubscription"}},[_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selectedSubscription),callback:function ($$v) {_vm.selectedSubscription=$$v},expression:"selectedSubscription"}},[_c('v-container',[_c('v-row',_vm._l((_vm.subscriptions),function(subscription,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('v-item',{attrs:{"value":subscription},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-card',{class:active ? 'active' : '',attrs:{"elevation":active ? 16 : 2},on:{"click":toggle}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{class:[
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? 'subtitle-1'
                        : 'subtitle-2',
                    ]},[_vm._v(_vm._s(subscription.name))]),_c('v-card-text',{class:[
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? 'subtitle-1'
                        : 'subtitle-2',
                    ],domProps:{"innerHTML":_vm._s(subscription.description)}}),_c('v-card-title',{class:[
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? 'subtitle-1'
                        : 'subtitle-2',
                    ]},[_vm._v(" "+_vm._s(subscription.price)+"€")]),_c('v-card-title',{class:[
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? 'subtitle-1'
                        : 'subtitle-2',
                    ]},[_vm._v(" "+_vm._s(subscription.valueDays)+" "+_vm._s(_vm.$t("days")))])],1),_c('v-avatar',{staticClass:"ma-5",attrs:{"size":"150","tile":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-currency-eur")])],1)],1)])]}}],null,true)})],1)}),1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Paypal',{attrs:{"item":_vm.selectedSubscription,"amount":_vm.selectedSubscription.price},on:{"processing-payment":function($event){_vm.paymentProcessing = true},"finished-payment":function($event){_vm.paymentProcessing = false}}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.paymentProcessing,"z-index":"999"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }