<template>
  <div id="home">
    <v-container>
      <div class="welcome-block">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <div class="play-icon">
              <play-icon />
            </div>
            <h1>Karaoke Studio D<sup>TM</sup></h1>
          </div>
        </div>
        <p>{{ $t("welcome_message") }}</p>
      </div>
      <div class="see-all">
        <router-link to="/artists" class="button button-primary sm">
          View All Artists
        </router-link>
      </div>
      <v-row>
        <v-col
          v-for="(artist, i) in randomArtists"
          :key="i + '-artist'"
          md="3"
          sm="4"
          xs="12"
        >
          <router-link
            :to="'/artist/' + artists[artist].name"
            class="landing-page-artist"
          >
            <img :src="artists[artist].src" alt="" />
            <h2>{{ artists[artist].name }}</h2>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getNews, createNews, editNews, deleteNews } from "@/services/news";
import { getArtists, uploadImage } from "@/services/songs";
import PlayIcon from "@/components/svg/PlayIcon.vue";

export default {
  name: "home",
  components: { PlayIcon },
  data() {
    return {
      artists: [],
      randomArtists: [],
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
  },
  methods: {
    selectArtist: function (artist) {
      this.$router.push("/artist/" + artist.name);
    },
    getRandomArtists() {
      // Shuffle array
      const shuffled = this.artists.sort(() => 0.5 - Math.random());

      // Get sub-array of first n elements after shuffled
      //       let selected = this.artists.slice(0, 8);
      let selected = this.artists;

      const parsedObj = [...selected];

      return parsedObj;
    },
  },
  beforeMount() {
    let self = this;
    getArtists(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/");
      } else {
        data.payload.forEach((item) => {
          let src = "/api/stream/" + item.replace(/ /g, "") + ".jpg";
          let artist = {
            name: item,
            src,
          };
          self.artists.push(artist);
          self.shuffledArtists = self.artists.sort(
            () => self.artists.length * Math.random()
          );
          // self.shuffledArtists = self.artists.slice(0, 8)
        });
        const arr = [];
        for (let i = 1; i <= self.artists.length; i++) {
          arr.push(i);
        }
        const randomElement = arr.sort(() => 0.5 - Math.random());
        self.randomArtists = randomElement.slice(0, 12);
        self.artists.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }
    });
  },
  mounted() {
    this.getRandomArtists();
  },
};
</script>
<style lang="scss" scoped></style>
