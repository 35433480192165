<template>
  <div id="Videos" class="videos" ref="Videos">
    <div v-if="isLogged" class="page-search-wrapper">
      <v-text-field
        ref="search"
        v-model="search"
        full-width
        hide-details
        placeholder="Search videos"
        single-line
        prepend-inner-icon="search"
        class="page-search"
      ></v-text-field>
    </div>
    <div v-if="isLogged" class="videos__wrapper">
      <div
        v-for="video in filteredVideos"
        :key="video._id"
        class="videos__single"
        @click.prevent="videoToggle(video._id)"
      >
        <div class="videos__single--inner">
          <div class="video-info">
            <h4>{{ video.songName }}</h4>
            <h6>{{ video.artist }}</h6>
          </div>
          <div class="video-image">
            <img :src="`/api/stream/${video.image}`" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isLogged"
      class="video-wrapper"
      :style="hideVideo ? 'display: none' : 'display: block'"
    >
      <video
        ref="video"
        :src="videoFile"
        preload="auto"
        style="width: 300px;"
        controls
        controlslist="nodownload"
      ></video>
      <button
          class="close-video"
          type="button"
          @click="stopVideo()"
      >
        <close-icon />
      </button>
    </div>
  </div>
</template>

<script>
import { getVideos, getVideoUrl } from "@/services/videos";
import CloseIcon from "@/components/svg/CloseIcon.vue";

export default {
  name: "videos",
  components: {CloseIcon},
  metaInfo() {
    return {
      title: `Karaoke Studio D - ${this.name}`,
      meta: [
        {
          name: "description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:card",
          content: this.name,
        },
        {
          name: "twitter:site",
          content: "@matricestudiod",
        },
        {
          name: "twitter:title",
          content: `Karaoke Studio D - ${this.name}`,
        },
        {
          name: "twitter:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:creator",
          content: "@matricestudiod",
        },
        {
          name: "twitter:image",
          content: `${window.location.origin}${this.imgPath}`,
        },
        {
          property: "og:title",
          content: `Karaoke Studio D - ${this.name}`,
        },
        {
          property: "og:url",
          content: `${window.location.origin}/videos`,
        },
        {
          property: "og:image",
          content: `${window.location.origin}${this.imgPath}`,
        },
        {
          property: "og:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno",
        },
        { property: "og:site_name", content: "Karaoke" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      videos: [],
      search: "",
      video: undefined,
      videoFile: null,
      videoLoaded: false,
      videoPlaying: false,
      hideVideo: true
    };
  },
  watch: {
    videoPlaying(value) {
      if (value) {
        return this.video.play();
      }
      this.video.pause();
    },
    $route: function (value) {
      if (!value.path.includes('videos')) {
        this.closeVideo();
        this.stop();
        this.stopVideo()
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    shuffleVideos() {
      const self = this
      const randomElement = self.videos.sort(() => 0.5 - Math.random());
      return randomElement
    },
    filteredVideos() {
      const search = this.search.toLowerCase();

      if (!search) return this.shuffleVideos;

      return this.shuffleVideos.filter((video) => {
        const text = `${video.artist.toLocaleLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ð|ď|đ/g, 'dj')} ${video.songName.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ð|ď|đ/g, 'dj')}`;

        return text.indexOf(search) > -1;
      });
    },
  },
  methods: {
    closeVideo() {
      this.stop();
    },
    toggleFullScreen() {
      const video = this.$refs.video;
      if (video !== null) {
        if (document.fullscreenElement === null) {
          video.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    },
    playVideo: function () {
      this.video.play();
    },
    play: function () {
      this.playing = true;
      this.audio.play();
    },
    stop: function () {
      this.playing = false;
    },
    stopVideo: function () {
      this.videoPlaying = false;
      this.video.currentTime = 0;
      this.hideVideo = true;
    },
    turnOffVideo() {
      const video = this.$refs.video;
      video.src = '';
      this.onLeavePIP();
    },
    videoToggle: function (videoId) {
      this.stop();
      this.hideVideo = false;
      if (this.videoPlaying) {
        const self = this;
        self.stopVideo();
        self.turnOffVideo()
        getVideoUrl(videoId, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.video.src = data.payload.url;
            if (self.video.requestFullscreen) {
              self.video.requestFullscreen();
            } else if (self.video.mozRequestFullScreen) {
              self.video.mozRequestFullScreen();
            } else if (self.video.webkitRequestFullscreen) {
              self.video.webkitRequestFullscreen();
            } else if (self.video.msRequestFullscreen) {
              self.video.msRequestFullscreen();
            }
            self.video.play();
          }
        });
      } else {
        const self = this;
        getVideoUrl(videoId, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.video.src = data.payload.url;
            if (self.video.requestFullscreen) {
              self.video.requestFullscreen();
            } else if (self.video.mozRequestFullScreen) {
              self.video.mozRequestFullScreen();
            } else if (self.video.webkitRequestFullscreen) {
              self.video.webkitRequestFullscreen();
            } else if (self.video.msRequestFullscreen) {
              self.video.msRequestFullscreen();
            }
            self.video.play();
          }
        });
      }
    },
    // playVideo(videoId) {
    //   this.$refs.videoPlayer.playVideo(videoId);
    // }
  },
  beforeMount() {
    let self = this;
    getVideos(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/");
      } else {
        self.videos = data.payload;
      }
    });
  },
  mounted() {
    this.audio = this.$el.querySelectorAll("audio")[0];
    this.video = this.$el.querySelectorAll("video")[0];
    this.video.disablePictureInPicture = true;
    // this.audio.addEventListener("timeupdate", this.update);
    // this.audio.addEventListener("loadeddata", this.load);
    // this.audio.addEventListener("pause", () => {
    //   this.playing = false;
    // });
    // this.audio.addEventListener("play", () => {
    //   this.playing = true;
    // });
    this.video.addEventListener("pause", () => {
      this.videoPlaying = false;
    });
    this.video.addEventListener("play", () => {
      this.videoPlaying = true;
    });
  },
};
</script>
